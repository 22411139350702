@import '_variables.scss';
@import '_mixins.scss';

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-line-truncate-text {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: calc(var(--ellipsis-font-size) * var(--ellipsis-line-height) * var(--ellipsis-lines-to-show)); /* Fallback for non-webkit */
  font-size: var(--ellipsis-font-size);
  line-height: var(--ellipsis-line-height);
  -webkit-line-clamp: var(--ellipsis-lines-to-show, 1);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  justify-content: space-around;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-auto {
  flex: 1;
}

.light-small-title {
  opacity: 0.7;
  color: black;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.dark-small-title {
  color: #333338;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.dark-smaller-title {
  color: #333338;
  font-size: 10px;
  font-weight: bold;
  line-height: normal;
}

.dark-title {
  color: #333338;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
}

.dark-md-title {
  color: #333338;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
}

.dark-large-title {
  color: #333338;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  height: 24px;

  &--condensed {
    font-stretch: condensed;
  }
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.align-self-end {
  align-self: flex-end;
}

.width-full {
  width: 100%;
}

.full {
  width: 100%;
  height: 100%;
}

.width-50\% {
  width: 50%
}

.width-60\% {
  width: 60%
}

.width-70\% {
  width: 70%
}

.width-80\% {
  width: 80%
}

.text-center {
  text-align: center;
}

.small-circle-image {
  border-radius: 24px;
  width: 24px;
  height: 24px;
  object-fit: cover;

  @include media($tablet)
  {
    border-radius: 32px;
    width: 32px;
    height: 32px;
  }
}

.hover-cursor {
  &:hover {
    cursor: pointer;
  }
}

.mr-3 {
  margin-right: 3px;
}

.mr-13 {
  margin-right: 13px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-44 {
  margin-bottom: 44px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.text-vertical-align-top {
  vertical-align: top;
}

.form-input {
  padding: 15px;
  height: 44px;
  border: 1px solid #8A8A8A;
  border-radius: 4px;
  background-color: #FFFFFF;
}
