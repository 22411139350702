@import 'utils.scss';

.advice {
  background-color: white; /* #F2F2F2; */
  margin-bottom: 100px;
  padding: 40px 0;
  @include media($tablet) {
    padding: 50px 0;
  }
  &__title {
    font-stretch: condensed;
    overflow-wrap: break-word;
    position: relative;
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    color: $black;
    margin-bottom: 0.75em;
    @include media($tablet) {
    font-size: 30px;
    }
    @include media($desktop) {
      font-size: 36px;
    }
    &__help {
      @include position(absolute, 50% 0 auto auto);
      transform: translateY(-50%);
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        fill: #9d9d9d;
        &:hover {
          fill: #646464;
        }
        @include media($tablet) {
          height: 25px;
          width: 25px;
        }
        @include media($desktop) {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  &__description {
    width: 100%;
    color: $black;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 0 22px 0;
    @include media($tablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 16px;
    }
  }
}
