@import 'utils.scss';

.faq {
  padding: 30px 0;

  @include media($tablet)
  {
    padding: 50px 0;
  }

  &__header {
    position: relative;
    padding: 10%;
    min-height: 180px;
    background-size: cover;
    margin-top: -30px;

    &__picture {
      @include position(absolute, 0 auto auto 0);
      overflow: hidden;
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        @include media($tablet) {
          width: auto;
        }
      }
    }
  }

  &__container {
    padding: 65px 30px !important;

    h1 {
      max-width: 230px;
      font-stretch: condensed;
      color: #333338;
      font-size: 30px;
      line-height: 36px;
      font-weight: bold;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }

  &__item {
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 20px 15px;

    &:hover {
      cursor: pointer;
    }

    &__question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: #333338;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      img {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin-left: 20px;
        opacity: .4;
        transition: all .4s ease;
      }
    }

    &__answer {
      transition: max-height .4s ease;
      overflow: hidden;
      border-radius: 0 0 4px 4px;

      p {
        margin-top: 10px;
        color: #333338;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        white-space: pre-wrap;
      }
    }
  }
}

;


