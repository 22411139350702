@import 'utils.scss';

.header {
  &__preheader {
    height: 35px;
    padding: 0 22px;
    @include media($desktop) {
      padding: 0 30px;
    }
  }
  &__logo {
    @include position(absolute, 36px auto auto 30px);
    z-index: 5;
    @include media($desktop) {
      height: 108px;
      width: 120px;
      top: 0;
      left: 0;
      background: white;
    }
    a {
      @include position(relative, 3px auto auto 0);
      z-index: 6;
      @include media($tablet) {
        @include position(relative, 10px auto auto 0);
      }
      @include media($desktop) {
        @include position(absolute, 36px auto auto 22px);
        top: 41px;
      }
      @include media($l-desktop) {
        top: 36px;
        left: 46px;
      }
    }
    &:after {
      width: 215px;
      height: 108px;
      z-index: 1;
      transform: skewX(-45deg);
      overflow: hidden;
      background: white;
      border-radius: 0 0 25px;
      @include media($desktop) {
        content: "";
        @include position(absolute, 0 -83px auto auto);
      }
      @include media($l-desktop) {
        right: -155px;
      }
    }
  }
}

.kverneland {
  .header {
    &__preheader {
      background: getProp(kverneland, color1);
    }
    &__logo a {
      display: inline-block;
      background: url(../img/kverneland.png) no-repeat;
      background-size: cover;
      width: 160px;
      height: 36px;

      @include media($desktop) {
        width: 140px;
        height: 30px;
      }
      @include media($l-desktop) {
        width: 160px;
        height: 36px;
      }
    }
  }
}

.vicon {
  .header__preheader {
    background: getProp(vicon, color1);
  }
  .header {
    &__logo {
      background: none;
      &:after {
        content: none;
      }
      a {
        display: inline-block;
        background: url(../img/vicon.png) no-repeat;
        background-size: cover;
        top: 4px;
        width: 64px;
        height: 36px;

        @include media($tablet) {
          top: 5px;
          width: 79px;
          height: 44px;
        }

        @include media($desktop) {
          top: 55px;
          width: 83px;
          height: 46px;
        }
      }
    }
  }
}

.kubota {
  .header__preheader {
    background: getProp(kubota, color1);
  }
  .header {
    &__logo {
      background: none;
      &:after {
        content: none;
      }
      a {
        display: inline-block;
        background: url(../img/kubota.png) no-repeat;
        background-size: cover;
        top: 6px;
        width: 140px;
        height: 30px;

        @include media($tablet) {
          top: 6px;
          width: 184px;
          height: 40px;
        }
        @include media($desktop) {
          top: 57px;
        }
        @include media($l-desktop) {
          top: 54px;
          width: 218px;
          height: 47px;
        }
      }
    }
  }
}
