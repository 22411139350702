.ComboBox {
    float: right;
    width: 150px;
    margin-top: -7px;
    margin-bottom: 3px;
    background-color: white;

    &__field {
        position: static;
        float: right;
        cursor: pointer;
        padding: 3px;
        
        width: 150px;

        font-weight: bold;
        font-size: 12px;

        border: 1px #8A8A8A solid;
        border-radius: 4px;

        &:hover {
            background-color: #EEEEEE;
        }  

        &:active {
            background-color: white;
        }  

        img {
            margin-right: 6px;
            vertical-align: middle;
            opacity: 0.8;
        }

        .value {
            float: right;            
            align-content: center;
            justify-content: center;
            align-items: center;
            display: flex;

            span {                
                justify-content: center;
                text-align: center;
                line-height: normal;
                margin-top: 3px;
            }

            img {
                justify-content: center;
                display: flex;
                margin-left: 4px;
                margin-right: 0px;
            }
        }
    }

    .extended {    
        background-color: white;
        border: 1px blue solid;
        border-radius: 4px;
    }

    &__close {
        position: fixed;
        width: 100%;
        height: 100%;

        left: 0;
        top: 0;

        z-index: 7;
    }

    
    &__list {
        position: absolute;

        padding: 8px 0px;
        width: 150px;

        margin-top: 30px;
        left: auto;

        background-color: white;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        border-radius: 4px;
        
        z-index: 8;


        &__item {

            cursor: pointer;
            padding: 12px;

            font-weight: bold;
            font-size: 12px;

            &:hover {
                background-color: #CECECE;
            }
        }
        
        .active {            
            background-color: #EEEEEE;
        }
    }
}