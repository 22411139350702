@import 'utils.scss';

.languages {
  &__close {
    @include position(absolute, 0 auto auto 0);
    z-index: 9;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0;
  }
  &__active {
    padding: 10px 0 0 0;
    cursor: pointer;
    text-align: right;
    font-weight: 700;
    font-size: 12px;
    color: white;
    &:after {
      content:"";
      display: inline-block;
      margin-left: 5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid white;
    }
  }
  &__flag {
    display: inline-block;
    width: 15px;
    height: 9px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
  &__list {
    z-index: 100;
    @include position(absolute, 45px 15px auto 15px);
    transition: max-height 0.4s;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    @include media($l-mobile) {
      max-width: 360px;
      width: 100%;
      left: auto;
      right: 30px;
    }
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      @include position(absolute, -10px 0 auto auto);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    &__item {
      display: block;
      padding: 5px 15px;
      width: 100%;
      font-weight: 700;
      color: white;
      cursor: pointer;
      @include media($l-mobile) {
        width: 50%;
      }
    }
  }
}

.kverneland {
  .languages__list {
    background: getProp(kverneland, color1);
    &:before {
      border-bottom: 10px solid getProp(kverneland, color1);
    }
    &__item--active,
    &__item:hover {
      background: lighten(getProp(kverneland, color1), 10%);
    }
  }
}

.vicon {
  .languages__list {
    background: getProp(vicon, color1);
    &:before {
      border-bottom: 10px solid getProp(vicon, color1);
    }
    &__item--active,
    &__item:hover {
      background: lighten(getProp(vicon, color1), 10%);
    }
  }
  .languages__active {
    color: $black;
    &:after {
      border-top-color: $black;
    }
  }
  .languages__list__item {
    color: $black;
  }
}

.kubota {
  .languages__list {
    background: getProp(kubota, color1);
    &:before {
      border-bottom: 10px solid getProp(kubota, color1);
    }
    &__item--active,
    &__item:hover {
      background: getProp(kubota, color1);
    }
  }
}
