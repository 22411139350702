@import '../../utils/scss/utils.scss';

.border-strategy-header {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 5px;

    .border-strategy-item {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;  
        padding-bottom: 10px;           
        flex-direction: column;
        cursor: pointer;

        .border-strategy-text {
            padding:5px;
        }
    }
    .inactive {
        border-bottom: rgba(51, 51, 56, 0.15) 2px solid; 
        opacity: 0.7;
    }

    .inactive:hover {
        opacity: 1;
        border-bottom: rgba(51, 51, 56, 0.3) 2px solid; 
    }

}


.vicon .border-strategy-header .active {
    border-bottom: getProp(vicon, color2) 2px solid;  
}

.kverneland .border-strategy-header .active {
    border-bottom: getProp(kverneland, color1) 2px solid;  
}

.kubota .border-strategy-header .active {
    border-bottom: getProp(kubota, color1) 2px solid;  
}


.border-settings-container {
    overflow-x: hidden;
    margin-top: 10px;
    
    .border-settings-movable{        
        transition: transform 0.5s ease-in-out;
    }

    div {
        float: left;
    }
}