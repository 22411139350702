@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-BdCn.woff2") format("opentype");
 font-weight: 900;
 font-style: normal; //Should be not condensed
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-BdCn.woff2") format("opentype");
 font-weight: 700;
 font-style: normal; //Should be not condensed
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-BdCn.woff2") format("opentype");
 font-weight: 700;
 font-style: normal;
 font-stretch: condensed;
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-Roman.woff2") format("opentype");
 font-weight: 500;
 font-style: normal;
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-Roman.woff2") format("opentype");
 font-weight: 400;
 font-style: normal;
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-Roman.woff2") format("opentype");
 font-weight: 300;
 font-style: normal;
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-Th.woff2") format("opentype");
 font-weight: 100;
 font-style: normal;
}

@font-face {
 font-family: "HelveticaNeueLTStd";
 src: url("./fonts/HelveticaNeueLTStd-BdCn.woff2") format("opentype");
 font-weight: bold;
 font-style: italic;
}
