@import 'utils.scss';

.tableahistory {
  max-height: 9999px;
  transition: max-height 0.7s;
  margin-right: -30px;
  overflow: visible;

  @include media($tablet) {
    margin-right: 0;
  }
  &__wrapper {
    width: 100%;
    overflow: visible;
    margin-bottom: -30px;
    padding-bottom: 30px;
    @include media($tablet) {
      overflow-x: visible;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  &__table {
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    @include media($tablet) {
      white-space: normal;
      display:  table;
      border-collapse: separate;
      border-spacing: 0 9px;
    }
  }
  &__header {
    display: inline-block;
    @include media($tablet) {
      display:table-row;
      &--col1 { width: 31%; }
      &--col2 { width: 21%; }
      &--col3 { width: 14%; }
      &--col4 { width: 12%; }
      &--col5 { width: 12%; }
      &--col6 { width: 10%; }
    }
    div {
      font-weight: 700;
      text-align: left;
      padding: 11px;
      border-bottom: 1px solid #dbdbdb;
      border-left: 1px solid #dbdbdb;
      overflow-x: hidden;
      font-size: 12px;
      &:first-child {
        border-top: 1px solid #dbdbdb;
      }
      @include media($tablet) {
        border-top: 0 !important;
        border-left: 0;
        padding: 18px;
        overflow-x: visible;
        display:table-cell;
        border-bottom: 0;
        border-left: 0;
        font-size: 14px;
      }
      @include media($tablet) {
        font-size: 16px;
      }
    }
  }
  &__row {
    cursor: pointer;
    box-shadow: 0 0 0 1px #dbdbdb;
    background: white;
    transition: background 0.3s;
    display: inline-block;
    @include media($tablet) {
      display:table-row;
    }
    &--inactive {
      cursor: default;
      background: white !important;
      div {
        column-span: 6;
      }
    }
    &:hover {
      background: rgba(219, 219, 219, 0.7);
    }
    div {
      font-size: 12px;
      text-align: left;
      font-weight: 400;
      vertical-align: middle;
      padding: 11px;
      border-bottom: 1px solid #dbdbdb;
      overflow-x: hidden;
      @include media($tablet) {
        overflow-x: initial;
        border-bottom: 0;
        padding: 18px;
        display:table-cell;
        font-size: 14px;
      }
      @include media($tablet) {
        font-size: 16px;
      }
    }
  }
  &__noresults {
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 400;
    color: #888;
    text-align: center;
    width: calc(100% - 30px);
    padding: 18px;
    @include media($tablet) {
      width: 100%;
    }
  }
}

.history-card {
  width: 93%;

  @include media($tablet) {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
