@import '../../utils/scss/utils.scss';

.advicebar {
  @include position(fixed, auto auto 0 0);
  z-index: 10;
  padding: 24px 30px;
  box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.1);
  width: 100%;
  display: flex;
  background: white;
  align-items: center;
  justify-content: space-between;
  hr {
    margin: 0;
    border: none;
    height: 0;
    border-bottom: 2px solid #C4C4C4;
    position: relative;
    top: -18px;
    min-width: 33px;
    @media(max-width: $s-mobile) {
      min-width: 19px;
    }
    @include media($tablet) {
      min-width: 50px;
      top: -40px;
    }
    @media(min-width: 1025px) {
      min-width: 90px;
    }
  }
  &__item {
    min-height: 60px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 38px;
    @include media($tablet) {
      min-height: 100px;
      max-width: none ;
    }
    span {
      font-family: Arial, sans-serif;
      text-align: center;
      line-height: 25px;
      font-weight: 700;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #C4C4C4;
      color: white;
    }
    h3 {
      padding-top: 9px;
      color: $black;
      font-weight: bold;
      line-height: 12px;
      text-align: center;
      font-size: 10px;
      @include media($tablet) {
        font-size: 14px;
        line-height: 16px;
      }
      @include media($desktop) {
        font-size: 18px;
      }
    }
    p {
      font-weight: 400;
      text-align: center;
      color: #454545;
      display: none;
      font-size: 12px;
      @include media($tablet) {
        display: block;
      }
      @include media($desktop) {
        font-size: 14px;
      }
    }
    &--current {
      span {
        background: #007B3B;
      }
      h3 {
        color: #007B3B;
      }
    }
    &--success {
      span {
        content: url('../img/icons/checkmark_icon.svg');
        padding: 5px;
      }
    }
    &--button {
      cursor: default;
      opacity: 1;
      background: transparent !important;
      text-align: center;
      flex-grow: 0;
      width: 0;
      @include media($tablet) {
        width: auto;
        margin-left: 10px;
      }
      .btn-mobile-rounded {
        @include position(absolute, auto -15px 0 auto);
        margin-bottom: 100px;

        @include media($tablet) {
          margin-bottom: 0;

          @include position(static, auto);
        }
      }
    }
  }
}
