@import 'utils.scss';

.graphic {
  height: 100%;
  width: 100%;

  &__title {
    display: flex;
    li {
      padding: 8px 0;
      font-weight: 700;
      flex-grow: 1;
      display: block;
      width: 25%;
      text-align: center;
    }
    span {
      padding-right: 7px;
      font-weight: 400;
      color: rgba(69, 69, 69, 0.3);
      font-size: 9px;
      display: block;
      text-align: right;
    }
  }
  &__content {
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 112%;
    }
    &__bars {
      @include position(absolute, 0 auto auto 0);
      display: flex;
      width: 100%;
      height: 100%;
      div {
        width: calc(25% - 1px);
        margin-right: 1px;
        align-self: flex-end;
        opacity: 0.65;
        background: $red;
        transition: all 0.3s;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        &:last-child {
          width: 25%;
          margin-right: 0;
        }
      }
      &.success div {
        background: $green;
      }
    }
    &__bg {
      @include position(absolute, 0 auto auto 0);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      div {
        width: 25%;
        height: 5px;
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        &:nth-child(4n+0) {
          border-right: 0;
        }
        &:nth-child(1n+37) {
          border-bottom: 0;
        }
      }
    }
  }
}
