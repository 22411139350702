@import 'utils.scss';

.resultoverall {
  background: white;
  padding: 15px 0;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  flex-wrap: wrap;

  &__vanes__image {    
    margin-left: 10px;
    max-width: 100%;
    max-height: 200px;
  }

  &__item {
    border-top: 1px solid $greylight;

    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    @include media($tablet) {
      font-size: 14px;
      margin: 0;
      padding: 7px 0 0 0;
      border-top: 0;
      border-right: 1px solid $greylight;
      width: 25%;
      &:last-child {
        border-right: 0;
      }
    }

    img {
      margin: 0 auto;
    }
    
    &--machine {
      text-align: left;
      border-top: 0;
      padding-left: 18px;
      @include media($tablet) {
        text-align: center;
        padding-left: 0;
      }
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin: 0 10px 15px 0;
        width: calc(100% - 10px);
        &:before {
          content: "";
          display: block;
          padding-top: 67%;
        }
        @include media($tablet) {
          width: 70%;
          margin: 0 auto 15px auto;
        }
      }
    }
    &--shape {
      text-align: left;
      border-top: 0;
      padding-right: 18px;
      @include media($tablet) {
        text-align: center;
        padding-right: 0;
      }
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: calc(100% - 10px);
        margin: 0 -10px 15px 10px;
        &:before {
          content: "";
          display: block;
          padding-top: 67%;
        }
        @include media($tablet) {
          width: 70%;
          margin: 0 auto 15px auto;
        }
      }
      h3 {
        padding-left: 10px;
        @include media($tablet) {
          padding-left: 0;
        }
      }
    }
    &--density {
      padding-top: 20px;
      margin-top: 16px;
      @include media($tablet) {
        padding: 7px 0 0 0;
        margin: 0;
      }
      div {
        position: relative;
        &:before {
          content: "";
          display: block;
          padding-top: 71.4%;
          @include media($tablet) {
            padding-top: 52%;
          }
        }
      }
      img {
        @include position(absolute, 0 auto auto 50%);
        width: 24%;
        height: auto;
        margin-bottom: 15px;
        transform: scale(1.6) translateX(-26%);
        transform-origin: top center;
        @include media($tablet) {
          width: 37%;
          transform: scale(1) translateX(-50%);
        }
      }
    }
    &--props {
      padding-top: 20px;
      margin-top: 16px;
      @include media($tablet) {
        padding: 7px 0 0 0;
        margin: 0;
      }
      .graphic {
        width: 55%;
        margin: 0 auto;
        border: 1px solid #dedede;
        margin-bottom: 15px;
        @include media($tablet) {
          width: 42%;
        }
      }
    }
  }
}
