@import '../../utils/scss/utils.scss';

.advice-result-item {
    width: 50%;
    display: flex;
    overflow: hidden;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 5px;

    .icon-wrapper {
        margin-top: 12px;
        left: 0;        

        @include media($tablet) 
        {
            margin-top: 2px;
        }

        height: 100%;
        display: block;   
        
        img {
            vertical-align: top;
        }
    }


    .advice-result-title {
        -webkit-font-smoothing: antialiased;        
        left: -24px;
        position: relative;      
        width: 10px;
        white-space: nowrap;
        overflow: visible;  
        @include media($tablet) {
            left: 8px;   
        }
    }
    
    .advice-result-value {
        @extend .dark-large-title;
        vertical-align: bottom;
        word-wrap: none;
        white-space: nowrap;
        top: 1px;
    }

    .NonCondensedValue {
        font-family: Arial, Helvetica, sans-serif;
    }
    
    .advice-result-unit {
        @extend .dark-smaller-title;
        margin-left: 2px;
        vertical-align: top;
    }

    .advice-result-container {
        position: relative;
        width: 10px;
        top: 15px;
        left: -2px;

        @include media($tablet) {
            left: -2px;
        }
    }

}