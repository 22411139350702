@import 'utils.scss';

.home {
  background: #FFFFFF;
  padding: 30px 0;
  @include media($tablet) {
    padding: 50px 0;
  }
  &__header {
    position: relative;
    background-size: cover;
    margin-bottom: 30px;
    margin-top: -30px;
    padding: 30px 0;
    @include media($tablet) {
      padding: 30px 0;
      margin-bottom: 60px;
      margin-top: -50px;
    }
    &__picture {
      z-index: 0;
      @include position(absolute, 0 auto auto 0);
      overflow: hidden;
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        @include media($tablet) {
          width: auto;
        }
      }
      &--mobile {
        @include media($tablet) {
          display: none;
        }
      }
      &--tablet {
        display: none;
        @include media($tablet) {
          display: block;
        }
        @include media($desktop) {
          display: none;
        }
      }
      &--desktop {
        display: none;
        @include media($desktop) {
          display: block;
        }
      }
    }
    &__box {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 30px 0;
      background: transparent;
      @include media($tablet) {
        padding: 60px 0;
      }
      @include media($desktop) {
        max-width: 586px;
        padding: 60px 0;
      }
      div {
        position: relative;
        z-index: 1;
      }
      h2 {
        font-weight: 700;
        color: #F2F2F2;
        margin-bottom: 10px;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 35px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
        @include media($tablet) {
          font-size: 30px;
        }
      }
      p {
        color: white;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
        padding-bottom: 20px;
        @include media($tablet) {
          font-size: 16px;
        }
      }
      .btn-primary {
        height: 50px;
        padding: 0 46px;
      }
    }
  }

  &__newbutton {
    margin-top: 15px;
    cursor: pointer;
    transition: all 0.3s;
    padding: 10px 30px;
    border: 1px solid #C7C7C7;
    border-radius: 100px;
    font-size: 16px;
    color: #B0B0B0;
    &:hover {
      background: $black;
      color: white;
    }
  }

  .home_legal_container {

    margin-top: 80px;

    background-color: white;

    .home_legal_text {
      text-align: center;
      margin: 8px;
    }

    a {
      font-weight: bold;
      
      &:hover {
        color: getProp(kubota, color2)
      }
    }
  }
}
