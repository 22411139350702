@import 'utils.scss';
.menu {
  position: relative;
  background: $greylight;
  min-height: 44px;
  @include media($tablet) {
    min-height: 59px;
  }
  &__slogan {
    display: none;
    @include position(absolute, 50% 30px auto auto);
    transform: translateY(-50%);
    text-transform: uppercase;
    @include media($xxl-desktop) {
      display: block;
    }
  }
  &__items {
    @include media($desktop) {
      margin-left: 195px;
    }
    @include media($l-desktop) {
      margin-left: 300px;
    }
    ul {
      @include position(absolute, 44px auto auto 0);
      max-height: 0;
      transition: max-height 0.5s;
      overflow: hidden;
      z-index: 10;
      background: #e7e7e7;
      width: 100%;
      @include media($tablet) {
        top: 55px;
      }
      @include media($desktop) {
        @include position(static, auto auto auto auto);
        background: transparent;
        max-height: initial;
        overflow: visible;
      }
    }
    li {
      display: block;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      border-bottom: 1px solid $greylight;
      @include media($desktop) {
        display: inline-block;
        border-bottom: 0;
      }
    }
    a {
      cursor: pointer;
      padding: 13px 20px 7px 30px;
      display: block;

      @include media($desktop) {
        padding: 20px 15px 14px 15px;
      }
      @include media($l-desktop) {
        padding: 20px 10px;
      }
      &:hover {
        color: $red;
      }
    }
  }
  &__toggle {
    @include position(absolute, 50% 15px auto auto);
    transform: translateY(-50%);
    @include media($desktop) {
      display: none;
    }
    span {
      display: block;
      background: $black;
      width: 22px;
      height: 3px;
      margin-bottom: 4px;
      transform-origin: 50% 50%;
      transition: all 0.4s;
    }
  }
  &--visible {
    ul {
      max-height: 700px;
    }
    .menu__toggle {
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      span:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }
}

.kverneland {
  .menu__slogan {
    height: 13px;
    width: auto;
  }
}

.vicon {
  .menu {
    min-height: 44px;
    @include media($tablet) {
      min-height: 55px;
    }
    @include media($desktop) {
      min-height: 85px;
    }
    &__slogan {
      color: getProp(vicon, color2);
    }
    &__items a {
      @include media($desktop) {
        padding: 35px 15px 20px 15px;
      }
      &:hover {
        color: getProp(vicon, color2);
      }
    }
  }
  .menu__slogan {
    height: 40px;
    width: auto;
  }
}

.kubota {
  .menu {
    min-height: 44px;
    @include media($tablet) {
      min-height: 55px;
    }
    @include media($desktop) {
      min-height: 85px;
    }
    &__slogan {
      color: getProp(kubota, color2);
    }
    &__items {
      @include media($desktop) {
        margin-left: 230px;
      }
      @include media($l-desktop) {
        margin-left: 300px;
      }
      a {
        @include media($desktop) {
          padding: 35px 15px 20px 15px;
        }
        &:hover {
          color: getProp(kubota, color2);
        }
      }
    }
  }
}

