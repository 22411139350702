@import 'utils.scss';

.help-content {

  span {    
    display: flex;
  }

  p {
    white-space: pre-wrap;
    padding-bottom: 27px;
  }

  img {
    float: left;
    margin-right: 8px;
  }

  h3 {
    float: left;
    font-weight: bold;
    align-self: flex-end;
  }
}

.help {
  @include position(absolute, 0 auto auto 0);
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &__close {
    cursor: pointer;
    @include position(absolute, 30px 15px auto auto);
    z-index: 102;
    svg {
      stroke: white;
      transition: transform 0.2s;
    }
    @include media($tablet) {
      top: 40px;
      right: 40px;
      svg {
        width: 20px;
        height: 20px;
        stroke: white !important;
      }
    }
    &:hover svg {
      transform: scale(0.8);
    }
  }
  &__back {
    @include position(absolute, 28px auto auto 10px);
    svg {
      stroke: $black;
      transition: transform 0.2s;
    }
    &:hover svg {
      transform: scale(0.8);
    }
    @include media($tablet) {
      display: none;
    }
  }
  &__wrapper {
    position: relative;
    z-index: 101;
    width: 200%;
    display: flex;
    align-items: stretch;
    min-height: 100%;
    flex-grow: 1;
    transition: transform 0.4s;
    @include media($tablet) {
      transition: none;
      width: 630px;
    }
  }
  &--ontext {
    .help__wrapper {
      transform: translateX(-50%);
      @include media($tablet) {
        transform: translateX(0%);
      }
    }
    .help__close svg {
      stroke: $black;
    }
  }
  &__menu {
    width: 50%;
    background: #002813;
    @include media($tablet) {
      width: 270px;
    }
    h2 {
      font-weight: 700;
      font-size: 20px;
      color: white;
      padding: 30px 15px;
      @include media($tablet) {
        padding: 110px 15px 60px 30px;
      }
    }
    li {
      cursor: pointer;
      padding: 15px;
      color: white;
      font-weight: 700;
      font-size: 16px;
      transition: background 0.2s;
      @include media($tablet) {
        padding: 15px 15px 15px 30px;
        &.active {
          background: $red;
        }
        &:hover {
          background: $red;
        }
      }
    }
  }
  &__text {
    position: relative;
    width: 50%;
    background: #F6F6F6;
    padding: 60px 15px;
    @include media($tablet) {
      width: 350px;
      padding: 60px 30px;
    }
    h2 {
      padding-top: 25px;
      font-weight: 700;
      font-size: 20px;
      color: $black;
      padding-bottom: 30px;
      @include media($tablet) {
        padding-top: 0;
      }
    }
    p {
      font-weight: 400;
      font-size: 12px;
      @include media($tablet) {
        font-size: 14px;
      }
    }
  }
  &__overlay {
    background: rgba(0, 0, 0, 0.65);
    @include position(fixed, 0 0 0 0);
  }
}

.vicon {
  .help__menu li:hover,
  .help__menu li.active {
    @include media($tablet) {
      background: getProp(vicon, color2);
    }
  }
  .help__menu {
    background: #071c2b;
  }
}

.kubota {
  .help__menu li:hover,
  .help__menu li.active {
    @include media($tablet) {
      background: getProp(kubota, color2);
    }
  }
  .help__menu {
    background: #032d2d;
  }
}
