@import 'utils.scss';

.btn-mobile-rounded {
  background: $red;
  transition: background 0.3s;
  height: 60px;
  width: 60px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  border-radius: 50%;
  text-indent: -999em;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  svg {
    stroke: white;
    width: 18px;
    height: 18px;
    @include position(absolute, 50% auto auto 50%);
    transform: translate(-50%, -50%);
  }
  @include media($desktop) {
    &:hover {
      background-color: darken($red, 10%);
    }
  }
  &--disabled {
    cursor: default;
    background: #979797 !important;
  }
  @include media($tablet) {
    height: auto;
    width: auto;
    box-shadow: initial;
    border-radius: 2px;
    text-indent: 0;
    padding: 15px 27px 12px 27px;
    text-transform: uppercase;
    svg {
      display: none;
    }
  }
  @include media($desktop) {
    padding: 19px 35px 16px 35px;
  }
  @include media($l-desktop) {
    padding: 19px 55px 16px 55px;
  }
}

.btn-primary {
  background: $red;
  transition: background 0.3s;
  border-radius: 4px;
  padding: 15px 27px 12px 27px;
  font-family: Arial,sans-serif;
  line-height: 1;
  color: white;
  font-size: 12px;
  font-weight: 700;
  @include media($tablet) {
    font-size: 14px;
  }
  @include media($desktop) {
    padding: 17px 27px 13px 27px;
    font-size: 15px;
  }
  cursor: pointer;
  &--disabled {
    cursor: default;
    background: #979797 !important;
  }
  @include media($desktop) {
    &:hover {
      background-color: darken($red, 10%);
    }
  }
}

.vicon {
  .btn-mobile-rounded {
    background: getProp(vicon, color2);
    @include media($desktop) {
      &:hover {
        background-color: darken(getProp(vicon, color2), 10%);
      }
    }
    &--disabled {
      background: #979797 !important;
    }
  }
  .btn-primary {
    background: getProp(vicon, color2);
    @include media($desktop) {
      &:hover {
        background-color: darken(getProp(vicon, color2), 10%);
      }
    }
  }
}

.kubota {
  .btn-mobile-rounded {
    background: getProp(kubota, color2);
    @include media($desktop) {
      &:hover {
        background-color: darken(getProp(kubota, color2), 10%);
      }
    }
    &--disabled {
      background: #979797 !important;
    }
  }
  .btn-primary {
    background: getProp(kubota, color2);
    @include media($desktop) {
      &:hover {
        background-color: darken(getProp(kubota, color2), 10%);
      }
    }
  }
}
