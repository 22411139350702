@import '../../utils/scss/utils.scss';

.buttonscontrol {
  height: 30px;
  width: 40px;
  border-radius: 17px;
  background-color: #FFFFFF;
  box-shadow: -5px 5px 10px 0 rgba(0,0,0,0.1);

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: #4B69FB;
    span {
      color: #ffffff;
    }
  }

  &--disabled {

    background-color: #E9E9E9;

    span {
      color: #8A8A8A;
    }
  }

  &__text {
    color: #4B69FB;
    font-size: 12px;
    font-weight: bold;
  }

  &__reload {
    height: 30px;
    width: 20px;
    transition: all .3s ease;

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: rotate(-360deg);
    }
  }
}
