@import 'utils.scss';

.fieldsettings {
  &__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .inputrange {
    padding-bottom: 10px;
    flex: 0 1 48%;
    margin-bottom: 15px;
    @media(max-width: $s-mobile) {
      flex: 0 1 50%;
    }
    @include media($tablet) {
      width: 50%;
    }
  }
}
