@import '../../utils/scss/utils.scss';

.disclaimer {
  @include position(fixed, 0 auto auto 0);
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    background: rgba(0, 0, 0, 0.65);
    @include position(fixed, 0 0 0 0);
  }

  &__box {
    position: relative;
    z-index: 101;
    max-width: 500px;
    padding: 30px;
    width: calc(100% - 30px);
    background: white;
    border-radius: 6px;

    &__scroll {
      overflow: auto;
      overflow-y: scroll;
      max-height: 400px;
      height: 60vh;
      margin-bottom: 27px;
      margin-right: -15px;
      padding-right: 15px;
      @include media($tablet) {
        padding-right: 30px;
        margin-right: -30px;
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.3);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        box-shadow: 0 0 1px rgba(255,255,255,.5);
      }
    }

    h2 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 18px;
      color: $black;
      padding-bottom: 12px;
      @include media($tablet) {
        padding-bottom: 27px;
        font-size: 22px;
      }
    }

    p {
      padding-bottom: 27px;
      font-size: 14px;
      font-weight: 400;
      white-space: break-spaces;
    }

    button {
      width: 100%;
      @include media($tablet) {
        width: auto;
      }
    }

    @include media($tablet) {
      padding: 60px;
    }
  }
}
