@import '../../utils/scss/utils.scss';

.expendable-card {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 4px;

    .header {
        background-color: white;
        width: 100%;
        display: flex;       
        height: 40px;
        padding: 0px 14px;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .header-title {
            text-align: left;   
            font-weight: Bold;
            font-size: 16px;         
            width: 100%;
            margin: auto;
        }

        img {
            margin: auto;
        }
    }
    
    .header.card-collapsed {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .header:hover {
        background-color: white;
            
        @include media($tablet) 
        {
            background-color: #F2F2F2;
        }
    }

    .content-expander {
        height: 0px;
        overflow: hidden;
        transition: height ease-in-out 0.7s 0s;
    }

    .content-container {
        padding: 15px;
        overflow: hidden;
    }

    .content.card-collapsed {
        max-height: 0px;
        padding-bottom: 0px;
        overflow: hidden;
        transition: max-height ease-in-out 0.7s 0s;
    }
}