@import 'utils.scss';

.otherapps {
  padding: 30px 0;
  @include media($tablet) {
    padding: 50px 0;
  }
  &__content {

    &__app  {
      display: flex;
      padding: 15px;
      margin-bottom: 15px;

      
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      border-radius: 4px;

      @include media($tablet) {
        margin-bottom: 30px;
        padding: 30px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }
    &__img {
      width: 20%;
      padding-right: 15px;
      @include media($tablet) {
        padding-right: 30px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__text {
      width: 80%;
      h2 {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 20px;
        @include media($tablet) {
          font-size: 24px;
        }
      }
      p {
        font-weight: 400;
        font-size: 12px;
        @include media($tablet) {
          font-size: 14px;
        }
      }
    }
  }
}
