@import 'utils.scss';

.machines {
  &__breadcrumb {
    color: #69696C;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
.machineslist {
  h3 {
    height: 100%;
    color: #333338;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 12px;
  }

  &__header {
    display: flex;

    .input-search {      
      flex-grow: 1;
      display: flex;
    }

    form {
      width: 100%;
    }
  }

  &__qrcode {
    padding: 8px;
    background-color: white;
    position: relative;
    top: 0px;
    right: 0px;

    @include media($tablet){
      display: none;
    }

    :hover {
      cursor: pointer;
      background-color: lightgray;
    }
  }


  &__brand {
  padding: 15px 0;
  }
  &__items__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5%;
  }
  &__item {
    position: relative;
    flex: 0 47%;
    max-width: 175px;
    margin: 0 1.5% 3% 1.5%;
    background: white;
    border: 2px;
    border-radius: 4px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
    @include media($tablet) {
      flex: 0 1 30%;
      max-width: 256px;
    }
    &--active {
      border-style: solid;
      border-color: #4B69FB;
      h3 {
        background-color: #4B69FB;
        color: white;
        margin: 0 -1px -1px -1px;
      }
    }
    &__image {
      margin: 0 auto;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 2px 2px 0 0;
      &:before {
        content: "";
        display: block;
        padding-top: 67%;
        width: 100%;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      padding: 15px 15px 10px 15px;
      @include media($tablet) {
        padding: 20px;
        font-size: 16px;
      }
    }

    &__category,
    &__machine {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}