@import 'utils.scss';

.shape__image {  
  margin-right: 15px;
  background-size: cover;
  width: 80px;
  flex-shrink: 0;
  border-radius: 4px;
}

.shapes {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;

  &__item {
    position: relative;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 15px 15px 15px 15px;
    background-color: #ffffff;
    border: 1px solid transparent;

    &--active {
      background-color: #F4FAFF;
      border-color: #4B69FB;
    }

    &--outOfRange {
      background-color: #DEDEDE;   
      border-color: #DEDEDE;
      cursor: default;   
    }

    &__colls {
      display: flex;
      height: 140px;
      @media(max-width: $tablet) {
        height: 160px;
      }
      @media(max-width: $l-mobile) {
        height: 190px;
      }

    }

    &__container {
      width: 70%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }

    &__description {
      color: rgb(51, 51, 56);
      font-size: 13px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;

      
      -webkit-line-clamp: 5;
      @media(max-width: $tablet) {
        -webkit-line-clamp: 6;
      }
      @media(max-width: $l-mobile) {
        -webkit-line-clamp: 8;
      }
      -webkit-box-orient: vertical;
      padding: 0px 0.5em 0px 0px;
      width: 100%;
      height: 100%;
    }

    &__tick {
      @include position(absolute, 42px -14px auto auto);
      z-index: 1;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background: #EDEDED;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

      @include media($tablet) {
        right: 20px;
      }

      @include media($desktop) {
      }

      svg {
        display: none;
        @include position(absolute, 50% auto auto 50%);
        margin-left: -6px;
        margin-top: -4px;
        width: 12px;
        height: 9px;
        fill: white;
      }
      &--active {
        background: #4B69FB;
        svg {
          display: block;
        }
      }
    }

    span {
      display: block;
      font-weight: 700;
      color: $black;
      margin-bottom: 5px;
    }

    img {
      display: block;
      width: 80px;
      object-fit: cover;
      transition: opacity 0.4s;
      border-radius: 4px;
    }

    .star-ratings {
      unicode-bidi: bidi-override;
      font-size: 12px;
      position: relative;
      margin: 0;
      font-style: normal;
      margin-top: -10px;
      .fill-ratings {
        padding: 0;
        position: absolute;
        z-index: 1;
        display: block;
        top: 0;
        left: 0;
        overflow: hidden;
        
        // Allows us to grab the width of the span elements
        span {
          display: inline-block;
          color: green;
        }
      }
      .empty-ratings {
        padding: 0;
        display: block;
        z-index: 0;

        span {          
          color: #ccc;
        }
      }
    }
    
    &__warning {
      font-size: 13px;
      font-weight: bold;
      overflow: hidden;
      margin-top: 10px;
      display: flex;

      img {
        height: 18px;
        width: 18px;  
        margin-right: 8px;      
      }
    }
  }
}
