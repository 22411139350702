@import 'utils.scss';
.applications {
  &__divider {
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
  }

  &__category {
    cursor: pointer;
    margin-top: 8px;
    border: 1px solid #dbdbdb;
    background: #e6e6e6;
    transition: background 0.3s;
    padding: 15px;
    font-size: 16px;
    color: $black;
    font-weight: 700;
    @include media($tablet) {
      padding: 18px;
    }
    svg {
      float: right;
      transition: transform 0.3s;
      width: 36px;
      height: 20px;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
    &--active {
      border-color: #005833;
    }
  }
  &--hidden {
    .applications__category svg {
      transform: rotate(180deg);
    }
    .tableapplications {
      max-height: 0;
    }
  }

  &__vaneset {
    background-color: white;

    position: relative;
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 16px;
    background-color: #ffffff;

    h1 {
      font-weight: bold;
      font-size: 12pt;
    }

    p {
      font-weight: bold;
      color: #5E5E5E;
      font-size: 10pt;
    }

    img {      
      max-width: 100%;
      max-height: 200px;
    }
  }

  &__content-expander {
    height: 0px;
    overflow: hidden;
    padding: 0px 15px 0px 15px;
    transition: height ease-in-out 0.7s 0s;
  }


  &__content.card-collapsed {
      max-height: 0px;
      padding-bottom: 0px;
      overflow: hidden;
      transition: max-height ease-in-out 0.7s 0s;
  }

  &__header {
    cursor: pointer;
  }

  &__header__image {
    cursor: pointer;
    padding: 10px 10px 0px 10px;
  }

  &__header__text {
    position: relative;
    cursor: pointer;
    padding: 15px 15px 15px 15px;

  }

  &__header__text:hover {
    @include media($desktop) {
      background-color: #eeeeee;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    cursor: pointer;
    max-height: 90px;
    margin-top: 12px;
    margin-bottom: 12px;

    &--active {
      background-color: #F4FAFF;
      border-color: #4B69FB;
    }

    :hover {
      background-color: #eeeeee;
      border-color: #4B69FB;
    }

    &--outOfRange {
      background-color: #DEDEDE;   
      border-color: #DEDEDE;
      cursor: default;   
    }

    &__colls {
      display: flex;
    }

    
    .star-ratings {
      unicode-bidi: bidi-override;
      font-size: 12px;
      position: relative;
      margin: 0;
      font-style: normal;
      margin-top: -13px;
      .fill-ratings {
        padding: 0;
        position: absolute;
        z-index: 1;
        display: block;
        top: 0;
        left: 0;
        overflow: hidden;
        
        // Allows us to grab the width of the span elements
        span {
          display: inline-block;
          color: green;
        }
      }
      .empty-ratings {
        padding: 0;
        display: block;
        z-index: 0;

        span {          
          color: #ccc;
        }
      }
    }
        
    img {
      display: block;
      width: 60px;
      height: 80px;

      @include media($tablet) {
        width: 80px;
      }

      object-fit: cover;
      transition: opacity 0.4s;
      border-radius: 4px;
    }
  }
}
