@import 'utils.scss';

.resultadvice {
  margin-top: 0px;

  &__controls {

    &__item {

      &--workingwidth {
        width: 100%;
        padding-top: 5px;
        padding-left: 9px;
        text-align: left;
        @include media($l-mobile) {
          padding-left: 0px;
          width: 50%;
        }
        @include media($tablet) {
          text-align: left;
          padding-left: 45px;
        }
        @include media($desktop) {
          width: 25%;
          text-align: center;
          padding-left: 9px;
        }
        img {
          vertical-align: middle;
        }
        p {
          display: inline-block;
          position: relative;
          font-weight: 700;
          margin-left: 10px;
          font-size: 20px;
          @include media($tablet) {
            font-size: 30px;
            margin-top: 5px;
          }
          @include media($desktop) {
            font-size: 34px;
            margin-top: 0px;
          }
          @include media($l-mobile) {
            margin-left: 20px;
          }
          span {
            @include position(absolute, -8px -5px auto auto);
            font-size: 10px;
            opacity: 0.3;
            width: 10px;
            text-align: left;
          }
        }
      }
      &--workingwidth {
        padding-top: 13px;
        @include media($tablet) {
          padding-top: 5px;
        }
      }
      &--application {
        width: 100%;
        padding-left: 9px;
        padding-top: 18px;
        text-align: left;
        white-space: nowrap;
        @include media($l-mobile) {
          padding-left: 0px;
          width: 50%;
        }
        @include media($tablet) {
          white-space: nowrap;
          padding-left: 45px;
        }
        @include media($desktop) {
          width: 25%;
          padding-left: 9px;
          text-align: center;
        }
        p {
          text-align: left;
          line-height: 1.2;
          vertical-align: middle;
          font-size: 18px;
          font-weight: 700;
          display: inline-block;
          white-space: normal;
          margin-left: 10px;
          padding-right: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
          @include media($tablet) {
            margin-left: 34px;
            padding-right: 0px;
            text-align: center;
            white-space: wrap;
            text-overflow: unset;
            width: auto;
            overflow:visible;
          }
          @include media($desktop) {
            margin-left: 20px;
          }
        }
      }
    }
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;
    padding-top: 10px;

    > .advice-result-item {

      &:nth-child(odd) {
        padding-right: 10px;
      }
    }
  }
}

.resultsettings {
  margin-top: 30px;
  border-bottom: 2px solid #EDEDED;
  padding: 0 18px 22px 18px;
  background: white;
  border-radius: 2px;

  @include media($tablet) {
    padding: 0 60px 10px 60px;
  }

  &__title {
    padding-bottom: 14px;
    font-weight: 700;
    font-size: 19px;
    @include media($tablet) {
      font-size: 24px;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &__item {
      width: 50%;
      padding-bottom: 23px;
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      @include media($tablet) {
        width: 33.3%;
        font-size: 16px;
      }
      @include media($desktop) {
        width: 25%;
      }
      img {
        @include position(absolute, 50% auto auto 50%);
        transform: translate(-50%, -50%);
        width: 100%;
      }
      div {
        vertical-align: middle;
        position: relative;
        width: 35px;
        height: 35px;
        margin-right: 12px;
        display: inline-block;
        @include media($tablet) {
          width: 45px;
          height: 45px;
        }
      }
      .inclination {
        width: 50px;
        @include media($tablet) {
          width: 75px;
        }
      }
      .extra-large {
        width: 100%;
        max-width: 137px;
      }
    }
  }
}
