@import '../../utils/scss/utils.scss';

.app__download {
  width: 100%;
  padding: 0.75rem 0.94rem;
  height: 5.62rem;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 100;
  display: flex;
  align-items: center;

  &--close__icon {
    width: 0.94rem;
    height: 0.94rem;
    margin-right: 0.94rem;
  }
  &--download__icon {
    width: 4rem;
    height: 4rem;
    border-radius: 0.94rem;
  }

  &--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.62rem;
    height: 100%;
    max-width: 50%;
    span:first-child {
      font-size: 1rem;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  &--button {
    position: absolute;
    right: 0.94rem;
    font-size: 1.12rem;
    font-weight: 400;
    text-transform: uppercase;
    color: $lightblue;
    letter-spacing: 1px;
  }
}
