 .input-search {
   position: relative;
   form {
     display: flex;
     min-height: 2.5rem;
     margin-bottom: 1.5rem;

     input {
       width: 100%;
       padding: .5rem .75rem;
       border: 1px solid #8A8A8A;
       border-radius: 4px;
       font-size: .875rem;

       &:focus {
         border-color: #4B69FB;
       }
     }

     img {
       position: absolute;
       width: 1.5rem;
       height: 1.5rem;
       right: .75rem;
       top: .55rem;
       cursor: pointer;
     }
   }
   span {
     position: absolute;
     top: 3rem;
     left: .1rem;
   }
 }
