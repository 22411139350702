.editor {
    background-color: white;

    &_header {
        padding-top: 30px;
        h2 {
            font-size: 20px;
            font-weight: bold;
        }

        p {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &_list_container{
        padding-top: 25px;
    }
    
    &_list_item{
        margin-bottom: 16px;
        
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        &_header {
            border-radius: 4px 4px 0px 0px;
            font-weight: bold;
            float: 'top';
            width: '100%';
            display: 'block';

            span{
                padding: 5px;
            }
        }

        .empty {
            background-color: orange;
        }
        
        .changed {
            background-color: lightblue;
        }

        p {
            font-size: 12px;
            color: #323439;
        }

        textarea {
            font-size: 12px;
            color: black;
        }


    }
}