@import 'utils.scss';

.preloading {
  @include position(fixed, 0 auto auto 0);
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: getProp(kverneland, color1) !important;
    width: 140px;
    height: 140px;
  }
}

.vicon {
  .preloading svg {
    display: block;
    fill: getProp(vicon, color1) !important;
  }
}

.kubota {
  .preloading svg {
    display: block;
    fill: getProp(kubota, color1) !important;
  }
}
