
.optionselector {
  display: flex;
  width: 100%;
  height: 100%;
  @media (min-width:768px){
    width: 96.5%;
  }
  label {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-overflow: ellipsis;
    padding: 6px 17px;
    max-width: 33.33%;
    color: #333338;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    cursor: pointer;
    border: 1px #8A8A8A solid;
  }

  .selected {
    border-color: #4B69FB !important;
    background-color: #4B69FB;
    color: white;
    font-weight: bold;
    z-index: 10;
  }

}
