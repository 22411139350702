@import 'utils.scss';

.video-list {
  width: 100vw;
  margin-left: -30px;
  padding-bottom: 20px;

  @include media($tablet) {
    width: auto;
    margin-left: 0;
    padding-bottom: 20px;

    &__entry {
      &:first-child {
        margin: 0 !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.video-item video {
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}

.video-item-thumb {
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}

.video-item .react-player__preview {
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}

.react-player__shadow{
  border: none;
  visibility: hidden;
  background-color: transparent;
}

.video-react .video-react-big-play-button {
  border: none;
  background-color: transparent;
}
