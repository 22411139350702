@import 'utils.scss';

.headerimage {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  margin-top: -30px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  padding-top: 31%;
  @include media($tablet) {
    margin-bottom: 50px;
    margin-top: -50px;
    padding-top: 26%;
  }
  @include media($desktop) {
    padding-top: 17%;
  }
  &__title {
    @include position(absolute, 50% auto auto 50%);
    z-index: 1;
    transform: translate(-50%, -50%);
    min-width: 100%;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 25px;
    font-weight: 700;

    
    text-shadow: 2px 2px 2px rgba(33, 33, 33, 0.8);

    @include media($tablet) {
      font-size: 45px;
    }
  }
  &__picture {
    z-index: 0;
    @include position(absolute, 0 0 auto auto);
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100% auto;
    &.mobile { display: block; }
    &.desktop { display: none; }
    &.tablet { display: none; }
    @include media($tablet) {
      &.desktop { display: none; }
      &.mobile { display: none; }
      &.tablet { display: block; }
    }
    @include media($desktop) {
      &.desktop { display: block; }
      &.mobile { display: none; }
      &.tablet { display: none; }
    }
  }
}
