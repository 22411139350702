@import 'utils.scss';

.about {
  padding: 30px 0;

  @include media($tablet) {
    padding: 50px 0;
  }

  font-weight: 400;
  &__header {
    position: relative;
    padding: 10%;
    min-height: 180px;
    background-size: cover;
    margin-top: -30px;

    &__picture {
      @include position(absolute, 0 auto auto 0);
      overflow: hidden;
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        @include media($tablet) {
          width: auto;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 5px !important;

    h1 {
      color: #333338;
      font-stretch: condensed;
      text-transform: uppercase;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 14px;
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    &__versions {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;


      span {
        color: #333338;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__text {
      font-size: 14px;
      margin-bottom: 30px;
      @include media($tablet) {
        font-size: 16px;
      }
    }
  }
}
