@import 'utils.scss';

.modal {
  @include position(absolute, 0 auto auto 0);
  z-index: 100;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    background: rgba(0, 0, 0, 0.65);
    @include position(fixed, 0 0 0 0);
  }
  &__cross {
    @include position(absolute, 10px 10px auto auto);
    width: 15px;
    height: 15px;
    cursor: pointer;
    svg  {
      width: 100%;
      height: auto;
      stroke: $black;
    }
    &:hover svg {
      stroke: $red;
    }
    @include media($tablet) {
      top:8px;
      right: 8px;
    }
  }
  &__box {
    position: relative;
    z-index: 101;
    max-width: 500px;
    padding: 24px;
    padding-top: 30px;
    width: calc(100% - 30px);
    background: white;
    border-radius: 6px;

    h2 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      color: $black;
      padding-bottom: 12px;
      @include media($tablet) {
        padding-bottom: 27px;
        font-size: 22px;
      }
    }

    p {
      padding-bottom: 27px;
      font-size: 12px;
      font-weight: 400;
      @include media($tablet) {
        font-size: 14px;
      }
    }

    button {
      width: 100%;
      @include media($tablet) {
        width: auto;
      }
    }

    button + button {
      margin-top: 10px;
      @include media($tablet) {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    @include media($tablet) {
      padding: 45px;
    }
  }
}

.help-icon-table {
  img {
    max-width: 45px;
  }
  tr {
    td {
      vertical-align: middle;
      &:first-child {
        padding-right: 5px;
      }
    }
  }
  th {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    text-align: left;
  }
}
