@import 'utils';

.inputrange {
  &__label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    color: $black;
    margin-bottom: 8px;
    @include media($tablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 16px;
    }
  }

  &__input {
    position: relative;
    height: 44px;
    display: flex;
    background-color: #FFFFFF;;
    border: 1px #8A8A8A solid;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-end;
    img {
      position: absolute;
      max-width: 23px;
      left: 15px;
      opacity: 0.5;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 12px;
      }
    }

    select,
    input {
      display: block;
      width: 100%;
      border: none;
      border-radius: 4px;
      font-size: 20px;
      height: 100%;
      outline: 0;
      font-weight: bold;
      color: #000000;
      background-color: transparent;
      padding-right: 5px;
      text-align: right;
    }

    span {
      font-weight: 400;
      color: #8A8A8A;
      padding-right: 15px;
      @media(max-width: $s-mobile) {
        padding-right: 5px;
      }
    }
    &:focus-within {
      border-color: #4B69FB;
      background-color: #F4FAFF;
      span, input {
        color: #4B69FB;
      }
      img {
        opacity: 1;
      }
    }
    &--active {
      color: #000000;
    }
    &--error {
      border-color: $red;
    }
  }

  &__error {
    position: absolute;
    top: 50px;
    font-size: 10px;
    color: $red;
    @include media($tablet) {
      font-size: 12px;
    }
  }
}
