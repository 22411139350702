@import 'utils.scss';

.fertilizerprops {
  display: flex;
  flex-wrap: wrap;

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    padding-top: 30px;
    @include media($tablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 18px;
    }
  }
  &__text {
    width: 100%;
    color: $black;
    font-weight: 700;
    font-size: 12px;
    padding: 5px 0 22px 0;
    @include media($tablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 16px;
    }
  }

  &__fields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items:flex-start;
    align-content:flex-start;
  }

  .sum-error {
    .inputrange__input {
      border-color: #d31822 !important;
    }
  }

  .inputrange {
    padding: 10px 0;
    text-align: center;
    max-width: none;
  }

  &__density {
    display: flex;
    align-items: flex-start;
    margin-bottom: 42px;
    width: 100%;
    .inputrange {
      text-align: left;
      width: 50%;
      @include media($tablet) {
        width: 23%;
    }
    }
  }
}
