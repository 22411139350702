@import 'utils.scss';

.autosetapp {
  padding: 30px 0;
  @include media($tablet) {
    padding: 50px 0;
  }
  &__content {
    @include media($tablet) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
   }
  }

  &__text {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
    @include media($tablet) {
      width: calc(66% - 60px);
      font-size: 16px;
    }
    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 18px;
    }
    p {
      margin-bottom: 20px;
      line-height: 1.5;
    }
    img {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    ul {
      margin-bottom: 20px;
      padding-left: 20px;
      li {
        list-style: square;
      }
    }
  }

  &__sidebar {
    width: 100%;
    @include media($tablet) {
      width: 34%;
    }
    &__box {
      padding: 25px;
      background: #fbfbfb;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    strong {
      text-transform: uppercase;
      font-size: 16px;
      color: #a8a8a8;
      font-weight: 700;
      display: block;
    }
    li {
      font-weight: 400;
      padding-bottom: 20px;
    }
  }
  &__button {
    margin-bottom: 20px;
    width: 100%;
    margin-top: 30px;
  }
}
