@import 'utils.scss';

.tableapplications {
  max-height: 9999px;
  transition: max-height 0.7s;
  margin-right: -30px;
  overflow: hidden;

  @include media($tablet) {
    margin-right: 0;
  }
  &__wrapper {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: -30px;
    padding-bottom: 30px;
    @include media($tablet) {
      overflow-x: visible;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  &__table {
    width: 100%;
    white-space: nowrap;
    @include media($tablet) {
      white-space: normal;
      display:  table;
      border-collapse: separate;
      border-spacing: 0 9px;
    }
  }
  &__header {
    display: inline-block;
    @include media($tablet) {
      display:table-row;
      &--col1 { width: 5%; }
      &--col2 { width: 21%; }
      &--col3 { width: 13%; }
      &--col4 { width: 15%; }
      &--col5 { width: 19%; }
      &--col6 { width: 27%; }
    }
    div {
      font-weight: 700;
      font-size: 12px;
      text-align: left;
      padding: 11px;
      border-bottom: 1px solid #dbdbdb;
      border-left: 1px solid #dbdbdb;
      overflow-x: hidden;
      @include media($tablet) {
        overflow-x: visible;
        display:table-cell;
        border-bottom: 0;
        padding: 18px;
        border-left: 0;
        font-size: 14px;
      }
      @include media($tablet) {
        font-size: 16px;
      }
    }
  }
  &__row {
    cursor: pointer;
    box-shadow: 0 0 0 1px #dbdbdb;
    background: white;
    transition: background 0.3s;
    display: inline-block;
    @include media($tablet) {
      display:table-row;
    }
    &--active {
      span {
        background: #398217 !important;
        svg {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &:hover {
      @include media($tablet) {
        background: rgba(219, 219, 219, 0.7);
      }
    }
    div {
      text-align: left;
      font-weight: 400;
      vertical-align: middle;
      padding: 11px;
      border-bottom: 1px solid #dbdbdb;
      overflow-x: hidden;
      font-size: 12px;
      @include media($tablet) {
        font-size: 14px;
        overflow-x: initial;
        padding: 18px;
        border-bottom: 0;
        display:table-cell;

      }
      @include media($desktop) {
        font-size: 16px;
      }
    }
  }
  &__check {
    span {
      transition: background 0.4s;
      position: relative;
      display: inline-block;
      background: #EDEDED;
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
    svg {
      transition: transform 0.4s;
      opacity: 0;
      transform: scale(0);
      @include position(absolute, 50% auto auto 50%);
      margin-left: -6px;
      margin-top: -4px;
      width: 12px;
      height: 9px;
      fill: white;
    }
  }
}



.bestMatch {
  .tableapplications__check {
    &:after {
      content: "";
      transform: translateY(-2px);
      margin-left: 10px;
      display: inline-block;
      background: url(../img/icons/ico_best_match.png) no-repeat;
      background-size: cover;
      width: 25px;
      height: 22px;
      @include media($tablet) {
        content: none;
      }
    }
  }
  &.tableapplications__row div {
    &:last-child {
      position: relative;
      padding-right: 45px;
      &:before {
        @include position(absolute, 50% 20px auto auto);
        transform: translateY(-50%);
        background: url(../img/icons/ico_best_match.png) no-repeat;
        background-size: cover;
        width: 22px;
        height: 19px;
      }
      @include media($tablet) {
        padding-right: 55px;
        &:before {
          content: "";
          width: 31px;
          height: 25px;
        }
      }
    }
  }
}
