@import 'utils.scss';

.result {
  &__warning {
    display: flex;
    background-color: #FFEDDB;
    align-items: center;
    height: 75px;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;

    &__title {
      color: #B36400;;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
      line-height: normal;
    }

    &__msg {
      color: #B36400;;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
    }

    img {
      margin-right: 15px;
    }
  }

}

.icon__button {

  &__icon {
    display: none !important;
    @include media(354px) {
      display: flex !important;
    }
  }

  &:active:not([data-disabled="true"]) {
    transform: scale(.98);
  }
}
