@import 'reset';
@import 'utils';
@import 'common';
@import './partials/fonts.scss';
@import './partials/buttons.scss';

html {
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-family: HelveticaNeueLTStd, Helvetica, Arial, sans-serif;
}
* {
  box-sizing: border-box;
  font-family: HelveticaNeueLTStd, Helvetica, Arial, sans-serif;
}
ol, ul { list-style: none; }
a { text-decoration: none; }
input[type='number'] { -moz-appearance:textfield; }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }
button { border:none; background: transparent; outline:0; }

:root {
  --active-border-color: #4B69FB;
  --inactive-color: #8A8A8A;
  --white: #ffffff;
  --light-gray-text: #333338;
  --graph-bar-active-background-color: #6ED59F;
  --error: #d31822;
  --box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
  --green1: #007B3B;
  --advice-title-section-bottom-margin: 0.75em;
  --gray1: #979797;
  --gray2: #F5F5F5;
  --gray3: #E9E9E9;
  --light-gray-color: #C4C4C4;
  --amber1: #FFEDDB;
  --amber2: #B36400;
}

body {
  font-family: HelveticaNeueLTStd, Helvetica, Arial,sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  color: $black;
  background: #ededed;
  //margin-bottom: 100px;
  overflow-y: scroll;
  &.whiteBackground {
    background: white;
  }
}

a {
  color: $black;
}

.container {
  margin: auto;
  padding: 0 20px;
  width: 100%;
  @include media($m-mobile) {
    padding: 0 30px;
  }
  @include media($tablet) {
    max-width: 900px;
  };
}

img {
  flex-shrink: 0;
}
