
.list-label {
  position: relative;
  margin-bottom: 8px;
  color: #333338;
  font-size: 12px;
  font-weight: bold;
  float: left;
}


.match-label {
  margin-bottom: 8px;
  color: #333338;
  font-size: 12px;
  font-weight: bold;
  float: right;
}

.card-image {
  margin: 12px;
  border-radius: 4px;
  object-fit: cover;
  overflow: visible;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  opacity: 0.87;

  @include media($tablet)
  {
    width: 32px;
    height: 32px;
  }
}