
$s-mobile: 330px;
$m-mobile: 350px;
$l-mobile: 400px;
$tablet: 768px;
$desktop: 992px;
$l-desktop: 1200px;
$xl-desktop: 1400px;
$xxl-desktop: 1500px;


$greylight: #F2F2F2;
$greydark: #888888;
$black: #323439;
$red: #d31822;
$green: #398217;
$lightblue: #579dff;

$sites: (
  kverneland: (
    color1: #007B3B,
    color2: #d31822,
  ),
  vicon: (
    color1: #fee49b,
    color2: #e10f2a,
    color3: #0c3451
  ),
  kubota: (
    color1: #169998,
    color2: #da6623,
  )
);

