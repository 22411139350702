@import 'utils.scss';

.contact {
  padding: 30px 0;
  @include media($tablet) {
    padding: 50px 0;
  }
  &__content {
    @include media($tablet) {
      flex-wrap: nowrap;
      display: flex;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      @include media($tablet) {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(66% + 40px);
      }
    }
    &__sidebar {
      width: 100%;
      h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      @include media($tablet) {
        padding-left: 60px;
        width: calc(34% - 40px);
      }
      img {
        width: 40px;
        margin-right: 10px;
        height: auto;
        @include media($desktop) {
          width: 45px;
        }
      }
    }
    &__item {
      background: #FBFBFB;
      padding: 25px 15px 15px 15px;
      width: 100%;
      margin-bottom: 20px;

      @include media($tablet) {
        width: calc(50% - 20px);
        margin-left: 10px;
        margin-right: 10px;
        padding: 55px 30px 30px 30px;
      }
      h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 6px;
      }
      li,
      p {
        font-size: 14px;
        font-weight: 400;
        color: #4A4A4A;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media($tablet) {
          font-size: 16px;
        }
      }
      ul {
        color: #4A4A4A;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid #DBDBDB;
        strong {
          color: $black;
          display: block;
          font-weight: 700;
        }
      }
      li {
        padding-bottom: 20px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
